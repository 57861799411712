<template>
  <div class="login-page">
    <Head title="找回密码" />
    <Warpper top="40">
      <div class="form-box">
        <FormItem type="tel" icon="user" v-model="tel" placeholder="请输入您的手机号"></FormItem>
        <FormItem type="text" icon="code" v-model="code" placeholder="请输入验证码">
          <span slot="right" class="code-txt" @click.stop="getCode">{{txt}}</span>
        </FormItem>
        <FormItem type="password" icon="password" v-model="password" placeholder="重置密码"></FormItem>
        <FormItem type="password" icon="password" v-model="password2" placeholder="再次输入新密码"></FormItem>
        <button class="login-btn" @click="onSubmit">确认重置</button>
        <button class="go-btn" @click="toLogin">返回登录</button>
      </div>
    </Warpper>
  </div>
</template>

<script>
import FormItem from "components/FormItem";
import { isWX } from "@/plugins/untils";
import { reqSendCode,reqGetPwd } from "@/network/api";
export default {
  data() {
    return {
      tel: "",
      password: "",
      password2: "",
      code: "",
      count: 60,
      txt: "发送验证码",
      isCountDown:false
    };
  },
  components: {
    FormItem
  },
  created() {
    // this.$toast({
    //   message:"123"
    // })
  },
  mounted() {},
  methods: {
    onSubmit() {
      if (this.tel == "") {
        return this.$toast({
          message: "请输入您的手机号"
        });
      }
      if (this.code=="") {
        return this.$toast({
          message: "请输入验证码"
        });
      }
      if (this.password == "") {
        return this.$toast({
          message: "请输入您的登录密码"
        });
      }
      if (this.password2 != this.password) {
        return this.$toast({
          message: "两次密码输入不一致"
        });
      }
      if (!/^1\d{10}$/.test(this.tel)) {
          return this.$toast({
            message: "手机号有误，请重新填写",
          });
      }
      // 调用重置密码接口
      reqGetPwd({
        username: this.tel,
        password: this.password,
        code:this.code
      }).then(async res => {   
        const { msg,code } = res;
        this.$toast({message:msg});
        this.$router.replace({
          name:"login"
        })
      }).catch (error=>{
        this.$toast({message:'系统错误'});
      })
    },
    toLogin() {
      this.$router.push({
        path:"/login"
      })
    },
    getCode(){
      if (this.tel == "") {
        return this.$toast({
          message: "请输入您的手机号"
        });
      }
      if (!/^1\d{10}$/.test(this.tel)) {
          return this.$toast({
            message: "手机号有误，请重新填写",
          });
      }
      if(this.isCountDown) return;
      this.isCountDown = true;
      // 获取验证码接口
      reqSendCode({
        phone:this.tel
      }).then(res=>{
        const { code,msg } = res;
        if(code!=0){
          return this.$toast({message:"短信发送失败"});
        }
        this.$toast({message:msg});
        this.countDown()
      })
    },
    countDown(){
      this.timer&&clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        this.txt = `${this.count}s`
        this.count --;
        if(this.count<0) {
          this.count = 60;
          this.isCountDown = false;
          return this.txt = "重新发送";
        }
        this.countDown();
      },1000)
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.code-txt {
  margin-left: 0.1rem;
  font-size: 0.26rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
  white-space: nowrap;
  width: 1.5rem;
  text-align: center;
}
.login-page {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
}
.form-box {
  width: 6.3rem;
  margin: 0.8rem auto 0;
  .login-btn {
    width: 100%;
    height: 0.88rem;
    background: linear-gradient(0deg, #328f96 0%, #69c4b8 100%);
    border-radius: 0.44rem;
    margin-top: 1.2rem;
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.34rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    border: 0;
  }
  button:focus {
    outline: 0;
  }
  .go-btn {
    width: 100%;
    height: 0.88rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.34rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #359298;
    border: .02rem solid #328f96;
    border-radius: 0.44rem;
    background:transparent;
  }
}
</style>
